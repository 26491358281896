.dropAreaMain {
  margin-top: 8%;
}
.dropzone {
  border: 2px dashed ;
  border-color: blue;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 22px;
}
.dropzone.dragging {
  border: 2px dashed green;
}
.dropzone input {
  visibility: hidden;
  width: 0;
  height: 0;
}
.dropzone label {
  display: inline-block;
}
.dropzone p {
  margin-top: 10px;
}
.dropzone ul {
  list-style: none;
  margin-top: 20px;
  padding: 0;
}
.dropAreaMain li {
  margin: 2% 0;
  color: #ffffff;
  font-size: 12px;
  font-family: sfRegular;
}
.nofiles {
  margin: 2% 0;
  color: #93979f;
  font-size: 12px;
  font-family: sfRegular;
}
.uploadBtn {
  border: none;
  background: #feca3d;
  font-family: centuryBold;
  font-size: 12px;
  color: #111826;
  padding: 7px 30px;
  border-radius: 6px;
  pointer-events: none;
  cursor: pointer;
}
.uploadCloud {
  width: 97px;
  height: auto;
  margin: 0 auto;
  pointer-events: none;
}
.dragText {
  color: #ffffff;
  font-size: 12px;
  font-family: centuryBold;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 1rem;
  pointer-events: none;
}
.subTxt {
  font-size: 12px;
  font-family: sfRegular;
  color: #93979f;
  pointer-events: none;
}
.previewRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #1c2434;
}
.previewRowImage {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.removeBtnMain {
  width: 10%;
}
.removeBtn {
  width: 20px;
  height: auto;
  border: none;
  background: none;
  cursor: pointer;
}
.fileNameMain {
  width: 60%;
}
.fileName {
  text-align: left;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0.5rem;
}
.previewVideo, .previewImage {
  width: 100px;
  height: auto;
}
.selectedFilesArea {
  width: 100%;
  height: 60vh;
  overflow-y: auto;
  margin-top: 3%;
  padding-right: 3%;
}
.selectedFilesArea::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
.selectedFilesArea::-webkit-scrollbar-track {
  background: transparent;
}
.selectedFilesArea::-webkit-scrollbar-thumb {
  background: #e2e2e2;
  border-radius: 5px;
}
.selectedFilesArea::-webkit-scrollbar-thumb:hover {
  background: #cac8c8;
}
@media screen and (max-width: 1200px) {
  .dropzone p {
    font-size: 12px;
 }
  .uploadBtn {
    font-size: 12px;
 }
  .nofiles {
    font-size: 12px;
 }
}
