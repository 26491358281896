.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000 99;
    z-index: 99999;
}
.cropModalCard {
    width: 50vw;
    height: auto;
    background-color: white;
    border-radius: 45px;
    padding: 1.5rem;
}
