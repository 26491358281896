.custom-select-box {
    z-index: 2;
}
.custom-select-box div.css-1s2u09g-control {
  border: solid 1px #d2d6da;
  border-radius: 5px;
}

.custom-select-box .css-6j8wv5-Input,
.custom-select-box .css-6j8wv5-Input input {
  height: 40px !important;
  border-radius: 0;
}

.navdropdownwhole .custom-arrow-dropdown {
  position: relative;
  top: 0;
}

.css-14el2xx-placeholder, .css-qc6sy-singleValue {
    font-size: 14px;
}

.custom-select-box div:last-child {
    font-size: 14px;
}

.srd-demo-canvas {
  height: 100vh;
}

.table-selectbox {
  width: 100% !important;
}

.table-selectbox .css-6j8wv5-Input,
.table-selectbox .css-6j8wv5-Input input {
  height: 35px !important;
  border-radius: 0;
}

.custom-file-input {
  border: solid 1px #d2d6da;
  padding: 10px 15px;
  width: 100%;
  border-radius: 5px;
}

.cus-select__single-value{
  font-size: 14px;
}

.cus-select__input{
  font-size: 14px;
}

.cus-select__placeholder {
  font-size: 14px;
}

.cus-select__option{
  font-size: 14px !important; 
  
}

.cus-select__menu{
  z-index: 5;
  background-color: #fff !important;

}

.selectWrapper {
  position: relative;
}

.selectWrapper label{
  position: absolute;
  background: #fff;
  z-index: 5;
  top: -2px ;
  left:4%;  
  font-size: 12px;
  line-height: 0.5;
  color: rgb(123, 128, 154);
  letter-spacing: 0.00938em;
}

