.cropper {
  position: relative;
  width: 100%;
  height: 50vh;
  border-radius: 45px;
  overflow: hidden;
  margin-bottom: 3%;
}
.cropModalActions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2%;
}
@media screen and (max-width: 768px) {
  .cropModalActions {
    justify-content: center;
 }
  .cropModalActions button {
    width: 30%;
 }
}
@media screen and (max-width: 575px) {
  .cropModalActions button {
    width: 40%;
 }
}
